@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

$bg-color: #1d1d1d;
$bg-color-light: #4e2a62;
// $bg-color: #a353cf;

.App {
  text-align: center;
  font-family: 'VT323', monospace;
  // font-family: 'Major Mono Display', monospace;
  // font-family: 'Share Tech Mono', monospace;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $bg-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}


@keyframes glow-small {
  from {
    text-shadow: 0 0 4px #fff, 0 0 8px #fff, 0 0 12px #e60073, 0 0 16px #e60073, 0 0 20px #e60073, 0 0 24px #e60073, 0 0 28px #e60073;
  }
  to {
    text-shadow: 0 0 8px #fff, 0 0 12px #ff4da6, 0 0 16px #ff4da6, 0 0 20px #ff4da6, 0 0 24px #ff4da6, 0 0 28px #ff4da6, 0 0 32px #ff4da6;
  }
}


@keyframes glow-xs {
  from {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #e60073, 0 0 8px #e60073, 0 0 10px #e60073, 0 0 12px #e60073, 0 0 14px #e60073;
  }
  to {
    text-shadow: 0 0 4px #fff, 0 0 6px #ff4da6, 0 0 8px #ff4da6, 0 0 10px #ff4da6, 0 0 12px #ff4da6, 0 0 14px #ff4da6, 0 0 16px #ff4da6;
  }
}



@keyframes glow-xxs {
  from {
    text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #e60073, 0 0 4px #e60073, 0 0 5px #e60073, 0 0 6px #e60073, 0 0 7px #e60073;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 3px #ff4da6, 0 0 4px #ff4da6, 0 0 5px #ff4da6, 0 0 6px #ff4da6, 0 0 7px #ff4da6, 0 0 8px #ff4da6;
  }
}


@keyframes glow-xxxs {
  from {
    text-shadow: 0 0 0px #fff, 0 0 1px #fff, 0 0 2px #e60073, 0 0 3px #e60073, 0 0 4px #e60073, 0 0 5px #e60073, 0 0 6px #e60073;
  }
  to {
    text-shadow: 0 0 1px #fff, 0 0 2px #ff4da6, 0 0 3px #ff4da6, 0 0 4px #ff4da6, 0 0 5px #ff4da6, 0 0 6px #ff4da6, 0 0 8px #ff4da6;
  }
}


@keyframes glow-xxxs-green {
  from {
    text-shadow: 0 0 0px #fff, 0 0 1px #fff, 0 0 2px #60e600, 0 0 3px #60e600, 0 0 4px #60e600, 0 0 5px #60e600, 0 0 6px #60e600;
  }
  to {
    text-shadow: 0 0 1px #fff, 0 0 2px #9dff4d, 0 0 3px #9dff4d, 0 0 4px #9dff4d, 0 0 5px #9dff4d, 0 0 6px #9dff4d, 0 0 8px #9dff4d;
  }
}

.glow {
  font-size: 5vh;
  color: #fff;
  text-align: center;
  // animation: App-logo-spin infinite 20s linear;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow-xxxs 3s ease-in-out infinite alternate;
}

.main-tree-list {
  list-style: none;
  width: 85%;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  li {
    margin: auto;
    width: 92%;
    padding-bottom: 4vh;
  }

  a, u {
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    color: white;
  }
}



.bg-canvas {
  position: absolute; 
  background-color: rgba(245, 222, 179, 0.1);
  z-index: 2;
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  span {
    left: 0;
    white-space: pre-line
  }
  .typewriter h1 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end 30;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
}